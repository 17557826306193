<template>
  <div>
    <BaseElFormItem
      v-for="field in fields"
      :key="field.prop"
      :label="field.label"
      :prop="field.prop"
    >
      <BaseElInput v-if="!field.disabled" v-model="syncFormData[field.key]" placeholder="請輸入 MerchantId" />
      <p v-else>{{ syncFormData[field.key] }}</p>
    </BaseElFormItem>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BasePaymentInputFields',
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const syncFormData = useVModel(props, 'formData', emit)

    return { syncFormData }
  },
})

</script>

<style lang="postcss" scoped>
</style>
